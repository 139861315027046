import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"fill-height py-2"},_vm._l((_vm.allRegisteredKiosks),function(kiosk,i){return _c(VCol,{key:i,attrs:{"md":"2","sm":"2","lg":"3","xl":"2","cols":"12"}},[_c(VCard,{staticClass:"cardBorderOffline",class:_vm.getCardClass(kiosk.data().onlineStatus),attrs:{"flat":""}},[_c(VCardActions,{staticClass:"subtitle-1 mb-n10"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(kiosk.data().name)+" ")]),(_vm.isSuperAdmin || _vm.isAdmin)?_c(VCol,{attrs:{"cols":"1"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({on:{"click":function($event){return _vm.remoteReferesh(kiosk)}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}],null,true)},[_c('span',[_vm._v("Remote Refresh Kiosk")])])],1):_vm._e()],1)],1),_c('br'),_c(VCardActions,{staticClass:"caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm._f("trimLength")(kiosk.data().location))+" ")]),_c(VCardActions,[(_vm.isSuperAdmin)?_c('span',{staticClass:"text-caption blue--text text-decoration-underline",on:{"click":function($event){return _vm.selectKiosk(kiosk)}}},[_vm._v(" Configurat... ")]):_vm._e(),(_vm.isAdmin)?_c('span',{staticClass:"text-caption blue--text text-decoration-underline",on:{"click":function($event){return _vm.avatarConfig(kiosk)}}},[_vm._v(" Avatar Conf... ")]):_vm._e(),_c(VSpacer),_c('div',[_c('editAndDeleteKiosk',{attrs:{"kioskType":"registered","kioskIndex":kiosk}})],1),_c('kioskInformation',{attrs:{"kioskObject":kiosk}})],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }